@use 'app/app-core/scss/scrollbar';
@use 'app/app-core/scss/globals';
@use '@angular/material' as mat;

html {
    color-scheme: light dark;
    @include mat.theme((
        color: (
            primary: mat.$azure-palette,
            tertiary: mat.$blue-palette,
        ),
        typography: Roboto,
        density: 0
    ));

     .dark{
         @include mat.theme((
            color: (
                primary: mat.$cyan-palette,
                tertiary: mat.$orange-palette,
            ),
            typography: Roboto,
            density: 0
        ));
    }
}


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}