@use '@angular/material' as mat;

.dense-3 {
    @include mat.all-component-densities(-5);
}

.qrcode {
    canvas {
        border-radius: 16px;
    }
}

.elevation-2 {
    box-shadow: var(--mat-sys-level2);
}

.mat-elevation-z1 {
    box-shadow: var(--mat-sys-level1);
}

.mat-elevation-z2 {
    box-shadow: var(--mat-sys-level2);
}