::-webkit-scrollbar {
    width: .5vw;
    height: .5vh;
}

::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: rgba($color: #999, $alpha: 0.5);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba($color: #bbb, $alpha: 0.5);
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}